<template>
  <v-container
    id="about"
    tag="section" fluid
  >
    <v-row align="center"
        justify="center">
      <v-col cols="12" align="center" class="ml-5 pa-0">
        <v-card flat max-width="1000">
          <v-row align="start">
            <!-- <base-subheading><span class="headline">{{ $t('MeeTeeMeeNgern1.workWithUs') }}</span></base-subheading> -->
            <v-col align="start" cols="12" class="pa-0 ma-1">
              <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.BranchGSBHeader') }}</strong></span>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" align="start" class="mb-0 pt-5">
              <p>
                {{ $t('MeeTeeMeeNgern1.FindBranch') }}
              </p>
              <p>
                <v-row row wrap>
                  <v-col cols="12" class="pt-0 pb-2 mb-0" lg="3">
                    <v-text-field
                      dense hide-details 
                      :label="$t('MeeTeeMeeNgern1.FindBranchKey')" 
                      v-model="keyword"
                      outlined>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 mb-0" lg="9"></v-col>
                  <v-col cols="12" class="pt-0 pb-2 mb-0" lg="4">
                    <v-btn dark
                      depressed
                      class="ma-0"
                      color="pink"
                      @click="FindBranch(1)"
                    >{{ $t('MeeTeeMeeNgern1.FindBranch') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 mb-0" lg="8"></v-col>
                  <BranchGSB-feed-card
                    v-for="(BranchGSB, i) in paginatedBranchGSB"
                    :key="BranchGSB.skey"
                    :size="3"
                    :value="BranchGSB"
                  />
                  <!-- :size="layout[i]" -->
                  <v-card></v-card>
                </v-row>
                <v-row align="center">
                  <v-col cols="3">
                    <base-btn
                      v-if="page !== 1"
                      class="ml-0"
                      square
                      title="Previous page"
                      @click="page--"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </base-btn>
                  </v-col>
                </v-row>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300">
      <v-card color="pink" dark>
        <v-card-text>
          <span class="font-weight-bold dark white--text">{{progressMessage}}</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'HomeAbout',
    components: {
      BranchGSBFeedCard: () => import('@/components/BranchGSBFeedCard'),
    },
    created: function () {
      this.retrieveFrontBranchGsb(0, localStorage.getItem('language'))
    },
    data: () => ({
      Find: '',
      layout: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      layoutProducgt: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
      page: 1,
      /* promotion: require('@/data/promotion.json'),
      product: require('@/data/product.json'),
      BranchGSB2: require('@/data/BranchGSB.json'), */
      keyword: '',
      BranchGSB: [],
      dialog: {
        dialogProgress: false,
      },
      limitShow: 0,
    }),
    computed: {
      ...mapState(['articles']),
      pages () {
        return Math.ceil(this.BranchGSB.length / 11)
      },
      paginatedBranchGSB () {
        /* const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.BranchGSB.slice(start, stop) */
        return this.BranchGSB
      },
      /* paginatedPromotion () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.promotion.slice(start, stop)
      },
      paginatedProduct () {
        const start = (this.page - 1) * 11
        const stop = this.page * 11

        return this.product.slice(start, stop)
      }, */
    },

    watch: {
      page () {
        window.scrollTo(0, 0)
      },
    },
    methods: {
      async selectLanguage (language) {
        await this.retrieveFrontBranchGsb(1, language)
      },
      FindBranch () {
        this.retrieveFrontBranchGsb(1, localStorage.getItem('language'))
      },
      async retrieveFrontBranchGsb (data, languageID) {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var aLimitShow = this.limitShow
        if (!aLimitShow) { aLimitShow = 0 }
        var keyword = this.keyword
        if (!keyword) {
          keyword = ''
        }
        await this.axios.post(process.env.VUE_APP_API + '/branch_gsb/retrieveFrontBranchGsb' + process.env.VUE_APP_DATABASE_TYPE, {
          data: data,
          limit: aLimitShow,
          language_id: languageID,
          keyword: this.keyword
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dialog.dialogProgress = false
              this.BranchGSB = response.data.data
            } else {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>
